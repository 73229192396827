require('core-js/es6/promise');

import $ from 'jquery';
import 'bootstrap';
import 'slick';
import swal from 'sweetalert2';

window.$ = window.jQuery = $;
window.swal = swal;

var card = require('card');
var FlipClock = require('FlipClock');
var easyAutocomplete = require('easyAutocomplete');